<template>
  <v-dialog v-model="dialog" width="800px" height="80vh" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <v-btn
            v-blur
            v-on="on"
            @click="dialog = true"
            class="msaBlue white--text"
          >
            <v-icon> mdi-file-export </v-icon>
          </v-btn>
        </template>
        Mass Export
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Export Documents </v-col>
          <v-col class="shrink">
            <v-btn icon @click="dialog = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs v-model="tab" background-color="lightGrey" grow>
        <v-tab> Documents </v-tab>
        <v-tab :disabled="!selectedDocuments.length"> Recipients </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table
            v-model="selectedDocuments"
            :headers="documentHeaders"
            :items="documents"
            :footer-props="{
              'items-per-page-options': [50],
            }"
            @update:page="stickToFooter"
            checkbox-color="msaBlue"
            class="signed-documents"
            item-key="id"
            show-select
          >
            <template v-slot:[`item.createdOnMobileByName`]="{ item }">
              <span :style="{ cursor: 'pointer', color: '#3564ac' }">
                {{ item.createdOnMobileByName }}
              </span>
            </template>
            <template v-slot:[`item.title`]="{ item }">
              <span :style="{ cursor: 'pointer', color: '#3564ac' }">
                {{ item.title }}
              </span>
            </template>
            <template v-slot:[`item.createdOnMobileAt`]="{ item }">
              {{ item.createdOnMobileAt | filterAsLocalDate }}
            </template>
            <template v-slot:[`item.completedAt`]="{ item }">
              {{ item.completedAt | filterAsLocalDate }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div
                :style="{ 'border-radius': '5px', width: '100px' }"
                class="green white--text"
              >
                {{ item.status }}
              </div>
            </template>
          </v-data-table>
          <v-card-actions ref="footer">
            <v-btn @click="selectAllDocuments" class="msaBlue white--text">
              SELECT ALL DOCUMENTS
            </v-btn>
            <v-spacer />
            <v-btn @click="dialog = false" color="red" dark text>
              CANCEL
            </v-btn>
            <v-btn
              :disabled="!selectedDocuments.length"
              @click="tab = 1"
              class="msaBlue white--text"
            >
              ADD RECIPIENTS
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model.trim="newRecipientEmail"
                  :rules="isEmailValid"
                  outlined
                  dense
                  label="Enter recipient's email"
                />
              </v-col>
              <v-col>
                <v-btn
                  color="msaBlue white--text"
                  @click="addRecipient"
                  :disabled="isNewRecipientButtonDisabled"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                :headers="recipientHeaders"
                :items="recipients"
                hide-default-footer
              >
                <template v-slot:[`item.recipient`]="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="deleteRecipient(item)"
                        icon
                        small
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    Remove recipient
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false" color="red" dark text>
              CANCEL
            </v-btn>
            <v-btn
              @click="createExportJob"
              :disabled="!recipients.length || !selectedDocuments.length"
              class="msaBlue white--text"
            >
              CREATE EXPORT
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DocumentSignOffMassExportDialog',
  props: {
    filters: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      tab: 0,
      dialog: false,
      documents: [],
      selectedDocuments: [],
      newRecipientEmail: '',
      recipients: [],
      recipientsSet: new Set(),
      documentHeaders: [
        {
          text: 'User',
          value: 'createdOnMobileByName',
          class: 'headerTextGrey--text lightGrey',
          width: '150px',
        },
        {
          text: 'Document',
          value: 'title',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Assigned Date',
          value: 'createdOnMobileAt',
          class: 'headerTextGrey--text lightGrey',
          width: '130px',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          class: 'headerTextGrey--text lightGrey',
          width: '140px',
        },
      ],
      recipientHeaders: [
        {
          text: 'Recipient',
          value: 'recipient',
          class: 'headerTextGrey--text lightGrey',
          width: '150px',
        },
        {
          text: 'Actions',
          value: 'actions',
          class: 'headerTextGrey--text lightGrey',
          align: 'right',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isEmailValid() {
      return [
        this.$helpers.validateEmail(
          this.newRecipientEmail,
          this.recipients.length ? false : true,
          true,
        ),
      ];
    },
    isNewRecipientButtonDisabled() {
      return (
        !this.$helpers.isValidEmail(this.newRecipientEmail) ||
        !this.newRecipientEmail.trim()
      );
    },
  },
  watch: {
    dialog(isOpen) {
      if (isOpen) {
        this.resetDialogValues();
        this.loadDocuments();
      }
    },
  },
  methods: {
    stickToFooter() {
      this.$nextTick(() => {
        this.$refs.footer.scrollIntoView();
      });
    },
    // Documents Tab
    loadDocuments() {
      this.recipients.push({ email: this.$store.getters.user.email });
      this.recipientsSet.add(this.$store.getters.user.email);

      const dialogFilters = JSON.parse(JSON.stringify(this.filters));
      dialogFilters.status = [
        this.$constants.ASSIGNED_DOCUMENT_STATUS.SUBMITTED,
      ];
      const dialogOptions = JSON.parse(JSON.stringify(this.options));
      dialogOptions.itemsPerPage = 1000; // Max num of jobs

      const url = 'get-assigned-documents-by-company?format=json';
      const params = {
        loaderText: 'Loading...',
        filters: dialogFilters,
        options: dialogOptions,
        ignoreIsSelectable: true,
      };

      this.$axios.post(url, params).then((response) => {
        this.documents = response.data.items;
      });
    },
    resetDialogValues() {
      this.documents = [];
      this.selectedDocuments = [];
      this.recipients = [];
      this.recipientsSet.clear();
      this.tab = 0;
    },
    selectAllDocuments() {
      this.selectedDocuments = JSON.parse(JSON.stringify(this.documents));
    },
    // Recipients Tab
    addRecipient() {
      if (this.recipientsSet.has(this.newRecipientEmail)) {
        this.newRecipientEmail = '';
        return;
      }

      this.recipients.push({ email: this.newRecipientEmail });
      this.recipientsSet.add(this.newRecipientEmail);
      this.newRecipientEmail = '';
    },
    deleteRecipient(recipientToRemove) {
      this.recipientsSet.delete(recipientToRemove.email);
      const indexToRemove = this.recipients.findIndex(
        (recipient) => recipient.email == recipientToRemove.email,
      );
      this.recipients.splice(indexToRemove, 1);
    },
    createExportJob() {
      const url = 'create-document-sign-off-export-job';
      const params = {
        loaderText: 'Loading...',
        documents: this.selectedDocuments.map((document) => document.id),
        recipients: this.recipients.map((recipient) => recipient.email),
      };

      this.$axios
        .post(url, params)
        .then(() => {
          this.$root.showMessage(
            'Export Started',
            'Your export job has been started.<br/>Recipients will receive an email with a link to download the exported documents once it is complete. The link will be valid for 30 days.<br/>The export may take up to 24 hours to process depending on the number of documents selected.',
            () => {},
            null,
            'OK',
            null,
            false,
            false,
          );
          this.dialog = false;
        })
        .catch(() => {});
    },
  },
};
</script>
