<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="documents.length"
        :value="documents.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected assignments
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Management </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Selected Assignments</th>
                      <th class="text-center" :style="{ width: '100px' }">
                        Remove From Selection
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in documents" :key="item.id">
                      <td>
                        {{ item.title }}
                      </td>
                      <td align="center">
                        <v-btn icon color="red" @click="unselect(item)">
                          <v-icon> mdi-minus-circle-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red" dark @click="deleteAll()">
          <v-icon class="mr-2">mdi-delete</v-icon>
          delete
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark color="red" @click="cancel">Cancel</v-btn>
        <v-btn class="msaBlue white--text" @click="syncToMobile()">
          <v-icon class="mr-2">mdi-sync</v-icon>
          Sync to mobile device
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssignedDocumentMassManage',
  props: {
    documents: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 249 / 2;
    },
  },
  methods: {
    syncToMobile() {
      const confirm = () => {
        const params = {
          loaderText: 'saving...',
          ids: this.documents.map((item) => item.id),
        };

        const url = 'sync-assigned-documents-to-mobile?format=json';

        this.$axios.post(url, params).then(() => {
          this.$emit('confirmed');
          this.close();
        });
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to sync these assignments to the mobile device?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    deleteAll() {
      const confirm = () => {
        const params = {
          loaderText: 'deleting...',
          ids: this.documents.map((item) => item.id),
        };
        const url = 'delete-assigned-documents?format=json';

        this.$axios.post(url, params).then(() => {
          this.$emit('confirmed');
          this.close();
        });
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete these assignments?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    unselect(doc) {
      this.$emit('onUnselect', doc);
      if (this.documents.length == 0) {
        this.close();
      }
    },
    cancel() {
      this.$emit('unselectAll');
      this.close();
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
