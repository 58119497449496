<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Filter Assigned Documents </v-col>
            <v-col class="shrink pr-6">
              <v-btn
                icon
                @click="
                  dialog = false;
                  thisFilters = { ...filters };
                "
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>

      <v-card-text class="mt-4">
        <v-row dense v-if="$route.name == 'AssignedDocuments'" align="center">
          <v-col class="pb-2" align="right">
            <v-checkbox
              v-model="thisFilters.requiringMyAttention"
              :true-value="1"
              :false-value="0"
              hint="Note: This filter disables all other filters."
              persistent-hint
              outlined
              dense
            >
              <template v-slot:label>
                <span class="font-weight-bold">Requiring My Attention</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row dense v-if="!thisFilters.requiringMyAttention">
          <v-col cols="12" class="pb-0 font-weight-bold"> Document Name </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.title"
              outlined
              hide-details
              placeholder="Enter a document name"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            $store.getters.isWebAdmin &&
            !thisFilters.requiringMyAttention &&
            $route.name != 'UserAssignedDocuments'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> User </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="thisFilters.createdOnMobileBy"
              multiple
              label="Select user(s)"
              hide-details
              :items="users"
              item-text="fullname"
              clearable
              deletable-chips
              @change="thisFilters.createdOnMobileBy = $event"
            />
          </v-col>
        </v-row>

        <v-row dense v-if="!thisFilters.requiringMyAttention">
          <v-col cols="12" class="pb-0 font-weight-bold"> Assigned Date </v-col>
          <v-col class="pb-2" cols="6">
            <v-menu
              v-model="assignedFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdFrom"
                no-title
                :max="assignedFromMax"
                @input="assignedFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="assignedToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdTo"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdTo"
                :min="assignedToMin"
                no-title
                @input="assignedToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense v-if="!thisFilters.requiringMyAttention">
          <v-col cols="12" class="pb-0 font-weight-bold">
            Completed Date
          </v-col>
          <v-col class="pb-2" cols="6">
            <v-menu
              v-model="completedFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.completedFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.completedFrom"
                :max="completedFromMax"
                no-title
                @input="completedFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="completedToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.completedTo"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.completedTo"
                no-title
                :min="completedToMin"
                @input="completedToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            !thisFilters.requiringMyAttention &&
            $route.name == 'CompanyAssignedDocuments'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> Supervisor </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="thisFilters.supervisorIds"
              multiple
              label="Select supervisor(s)"
              hide-details
              :items="supervisors"
              item-text="fullname"
              clearable
              deletable-chips
              @change="thisFilters.supervisorIds = $event"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            $store.getters.isWebAdmin &&
            !thisFilters.requiringMyAttention &&
            $route.name != 'UserAssignedDocuments'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> Group </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="thisFilters.label"
              multiple
              label="Select group(s)"
              hide-details
              :items="labels"
              clearable
              deletable-chips
              @change="thisFilters.label = $event"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            !thisFilters.requiringMyAttention &&
            $route.name != 'UserAssignedDocuments'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> Project </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="thisFilters.projectIds"
              multiple
              label="Select project(s)"
              hide-details
              :items="projects"
              clearable
              deletable-chips
              @change="thisFilters.projectIds = $event"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            !thisFilters.requiringMyAttention &&
            $route.name != 'UserAssignedDocuments'
          "
        >
          <v-col cols="12" class="pb-0 font-weight-bold"> Contact </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="thisFilters.signedOffByContacts"
              multiple
              label="Contact(s)"
              hide-details
              :items="contacts"
              item-text="fullname"
              clearable
              deletable-chips
              @change="thisFilters.signedOffByContacts = $event"
            />
          </v-col>
        </v-row>

        <v-row dense v-if="!thisFilters.requiringMyAttention">
          <v-col cols="12" class="pb-0 font-weight-bold"> Status </v-col>
          <v-col cols="12">
            <v-btn-toggle
              v-model="thisFilters.status"
              mandatory
              multiple
              color="msaBlue"
              :style="{ width: '100%' }"
            >
              <template v-slot:default>
                <v-row dense>
                  <v-col cols="6">
                    <v-btn
                      :style="{ width: '100%' }"
                      class="text-capitalize"
                      :value="2"
                      active-class="msaBlue white--text"
                      outlined
                    >
                      assigned
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      :style="{ width: '100%' }"
                      class="text-capitalize"
                      :value="3"
                      active-class="msaBlue white--text"
                      outlined
                    >
                      submitted
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="clear()"> clear </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn class="white msaBlue--text" @click="apply()">
                apply
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssignedDocumentsFilter',
  props: {
    filters: {
      type: Object,
    },
  },
  watch: {
    'thisFilters.requiringMyAttention'(val) {
      if (!val) {
        this.thisFilters.title = '';
        this.thisFilters.createdFrom = '';
        this.thisFilters.createdTo = '';
        this.thisFilters.completedFrom = '';
        this.thisFilters.completedTo = '';
        this.thisFilters.label = [];
        this.thisFilters.status = [2, 3];
        this.thisFilters.signedOffByContacts = [];
        this.thisFilters.projectIds = [];
        this.thisFilters.supervisorIds = [];
      }
    },
    dialog(val) {
      if (val && !this.loaded && this.$route.name != 'UserAssignedDocuments') {
        this.loadFilterItems();
      }
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.thisFilters) !=
        JSON.stringify(this.$constants.ASSIGNED_DOCUMENTS_DEFAULT_FILTERS)
      );
    },
    assignedToMin() {
      return this.thisFilters.createdFrom == ''
        ? undefined
        : this.thisFilters.createdFrom;
    },
    completedToMin() {
      return this.thisFilters.completedFrom == ''
        ? undefined
        : this.thisFilters.completedFrom;
    },
    assignedFromMax() {
      return this.thisFilters.createdTo == ''
        ? undefined
        : this.thisFilters.createdTo;
    },
    completedFromMax() {
      return this.thisFilters.completedTo == ''
        ? undefined
        : this.thisFilters.completedTo;
    },
    supervisors() {
      return this.users.filter((u) => u.isSupervisor);
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: JSON.parse(
        JSON.stringify(this.$constants.ASSIGNED_DOCUMENTS_DEFAULT_FILTERS),
      ),
      assignedFromMenu: false,
      assignedToMenu: false,
      completedFromMenu: false,
      completedToMenu: false,
      users: [],
      labels: [],
      contacts: [],
      projects: [],
      loaded: false,
    };
  },
  methods: {
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.ASSIGNED_DOCUMENTS_DEFAULT_FILTERS),
      );
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      Object.keys(this.thisFilters).forEach((k) => {
        this.thisFilters[k] = this.thisFilters[k] ?? '';
      });
    },
    setFilters() {
      this.thisFilters = JSON.parse(JSON.stringify(this.filters));
    },
    loadFilterItems() {
      const params = {
        loaderText: 'loading...',
        filters: this.filters,
      };
      const url = 'get-data-for-assigned-document-filters?format=json';
      this.$axios.post(url, params).then((response) => {
        this.labels = response.data.labels;
        this.contacts = response.data.contacts;
        this.projects = response.data.projects;
        this.users = response.data.users;
        this.loaded = true;
      });
    },
  },
};
</script>
