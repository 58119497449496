<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by document name..."
        />
      </v-col>
      <v-col class="grow" align="right">
        <AssignedDocumentsFilter
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
      <v-col
        v-if="this.$route.name == 'CompanyAssignedDocuments'"
        class="shrink"
      >
        <DocumentSignOffMassExportDialog
          :filters="filters"
          :options="options"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="exportItems()"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          Export
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Assigned Documents
            <v-badge
              inline
              class="ml-2"
              bordered
              :content="itemsCount"
              :value="itemsCount"
            />
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :items="items"
            :server-items-length="itemsCount"
            :must-sort="true"
            :options.sync="options"
            checkbox-color="msaBlue"
            :show-select="$route.name == 'CompanyAssignedDocuments'"
            class="signed-documents"
            item-key="id"
            :no-data-text="
              filterApplied ? 'No results found' : $vuetify.noDataText
            "
          >
            <template
              v-slot:[`item.createdOnMobileByName`]="{ item }"
              v-if="$store.getters.isWebAdmin"
            >
              <span
                :style="{ cursor: 'pointer', color: '#3564ac' }"
                @click="goToEmployee(item)"
              >
                {{ item.createdOnMobileByName }}
              </span>
            </template>
            <template v-slot:[`item.createdOnMobileByName`]="{ item }" v-else>
              {{ item.createdOnMobileByName }}
            </template>
            <template v-slot:[`item.title`]="{ item }">
              <span
                :style="{ cursor: 'pointer', color: '#3564ac' }"
                @click="goToDetails(item)"
              >
                {{ item.title }}
              </span>
            </template>
            <template v-slot:[`item.createdOnMobileAt`]="{ item }">
              {{ item.createdOnMobileAt | filterAsLocalDate }}
            </template>
            <template v-slot:[`item.completedAt`]="{ item }">
              {{ item.completedAt | filterAsLocalDate }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div
                :class="statusClass(item.status)"
                :style="{ 'border-radius': '5px', width: '100px' }"
              >
                {{ item.status }}
              </div>
            </template>

            <template
              v-slot:[`item.actions`]="{ item }"
              v-if="$route.name == 'AssignedDocuments'"
            >
              <v-tooltip bottom v-if="canSignOff(item)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    class="px-0"
                    v-on="on"
                    @click="goToDetails(item)"
                  >
                    <v-icon color="msaBlue"> mdi-draw </v-icon>
                  </v-btn>
                </template>
                Sign Off
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    class="px-0"
                    v-on="on"
                    @click="goToDetails(item)"
                  >
                    <v-icon color="msaBlue"> mdi-information-outline </v-icon>
                  </v-btn>
                </template>
                Details
              </v-tooltip>
            </template>

            <template
              v-slot:[`item.actions`]="{ item }"
              v-else-if="$route.name == 'UserAssignedDocuments'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    class="px-0"
                    v-on="on"
                    @click="goToDetails(item)"
                  >
                    <v-icon color="msaBlue"> mdi-information-outline </v-icon>
                  </v-btn>
                </template>
                Details
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }" v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="goToDetails(item)"
                    class="px-0"
                    icon
                    small
                  >
                    <v-icon color="msaBlue"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                Edit
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="disableDelete(item)"
                    @click="deleteItem(item)"
                    class="px-0"
                    icon
                    small
                  >
                    <v-icon color="red"> mdi-delete </v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <AssignedDocumentMassManage
      :documents="selected"
      v-if="selected.length > 0"
      @confirmed="
        selected = [];
        getItems();
      "
      @onUnselect="selected = selected.filter((i) => i.id != $event.id)"
      @unselectAll="selected = []"
    />
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import AssignedDocumentsFilter from '@/components/Filters/AssignedDocumentsFilter.vue';
import AssignedDocumentMassManage from '@/components/AssignedDocuments/AssignedDocumentMassManage.vue';
import DocumentSignOffMassExportDialog from '@/components/DocumentSignOffMassExportDialog.vue';
import { phantomAccessCheck } from '../global/functions.js';

export default {
  name: 'AssignedDocumentsPage',
  components: {
    SearchBar,
    AssignedDocumentsFilter,
    AssignedDocumentMassManage,
    DocumentSignOffMassExportDialog,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );
        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
    // sticky selection
    selected: {
      deep: true,
      handler(val) {
        this.$store.commit('updateAssignedDocumentSelected', val);
      },
    },
  },
  computed: {
    statusClass() {
      return (status) => {
        switch (status) {
          case 'Assigned':
            return 'red white--text';
          case 'Submitted':
            return 'green white--text';
          default:
            return '';
        }
      };
    },
    disableDelete() {
      return (item) => {
        if (
          item.documentSignOffStatusId ==
          this.$constants.ASSIGNED_DOCUMENT_STATUS.ASSIGNED
        ) {
          return false;
        }

        return true;
      };
    },
    canSignOff() {
      return (item) =>
        item.documentSignOffStatusId ==
          this.$constants.ASSIGNED_DOCUMENT_STATUS.ASSIGNED &&
        item.createdOnMobileBy == this.$store.getters.user.id &&
        this.$route.name == 'AssignedDocuments';
    },
    canViewDetails() {
      if (this.$store.getters.isWebAdmin) {
        return true;
      } else {
        return (item) => item.createdOnMobileBy == this.$store.getters.user.id;
      }
    },
    headers() {
      const h = [
        {
          text: 'Document',
          value: 'title',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Assigned Date',
          value: 'createdOnMobileAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '130px',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '140px',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '110px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          sortable: false,
          width: '120px',
        },
      ];

      if (this.$route.name != 'UserAssignedDocuments') {
        h.unshift({
          text: 'User',
          value: 'createdOnMobileByName',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '150px',
        });
      }
      return h;
    },
    isWebAdmin() {
      return this.$store.getters.isWebAdmin;
    },
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.ASSIGNED_DOCUMENTS_DEFAULT_FILTERS)
      );
    },
  },
  data() {
    return {
      userComment: '',
      items: [],
      itemsCount: 0,
      filters: JSON.parse(
        JSON.stringify(this.$constants.ASSIGNED_DOCUMENTS_DEFAULT_FILTERS),
      ),
      options: {
        sortBy: ['createdOnMobileAt'],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      selected: [],
      confirmDialog: false,
      selectedDocument: null,
    };
  },
  methods: {
    getItems() {
      const params = {
        loaderText: 'Loading...',
        filters: this.filters,
        options: this.options,
      };

      let url = '';

      if (this.$route.name == 'CompanyAssignedDocuments') {
        url = 'get-assigned-documents-by-company?format=json';
      }
      if (this.$route.name == 'AssignedDocuments') {
        url = 'get-assigned-documents-by-user?format=json';
      }
      if (this.$route.name == 'UserAssignedDocuments') {
        url = 'get-user-assigned-documents?format=json';
        params.userId = this.$route.params.id;
      }

      this.$axios.post(url, params).then((response) => {
        this.items = response.data.items;
        this.itemsCount = response.data.count;
        if (this.$route.name == 'UserAssignedDocuments') {
          this.$store.commit('updateSelectedUser', {
            ...response.data.user,
            status: response.data.user.status == 'Active' ? 1 : 0,
          });
        }
      });
    },
    applyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.options.page = 1;
      this.updateUrl();
    },
    goToEmployee(payload) {
      this.$router.push({
        name: 'UserDetails',
        params: { id: payload.createdOnMobileBy },
      });
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(
        query,
        'createdOnMobileAt',
        true,
      );
      this.selected = [...this.$store.getters.assignedDocumentSelected];
      this.getItems();
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.ASSIGNED_DOCUMENTS_DEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'createdOnMobileAt',
        true,
      );
      params = { ...params, ...options };
      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    onSearch(event) {
      this.filters.title = event;
      this.options.page = 1;
      this.updateUrl();
    },
    downloadDocument(item) {
      const params = {
        id: item.documentId,
        revision: item.revision,
        loaderText: 'Loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-private-library-document?format=json', params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, item.originalFilename);
        });
    },
    deleteItem(item) {
      const confirm = () => {
        const params = {
          loaderText: 'Loading...',
          ids: [item.id],
        };

        const url = 'delete-assigned-documents?format=json';
        this.$axios.post(url, params).then(() => {
          this.getItems();
          const index = this.selected.findIndex((i) => i.id == item.id);
          if (index > -1) {
            this.selected.splice(index, 1);
          }
        });
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this assignment?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
        false,
      );
    },
    exportItems() {
      const params = {
        loaderText: 'Loading...',
        filters: this.filters,
        options: this.options,
      };

      let url = '';

      if (this.$route.name == 'CompanyAssignedDocuments') {
        url = 'export-assigned-documents-by-company?format=json';
      }
      if (this.$route.name == 'AssignedDocuments') {
        url = 'export-assigned-documents-by-user?format=json';
      }
      if (this.$route.name == 'UserAssignedDocuments') {
        url = 'export-user-assigned-documents?format=json';
        params.userId = this.$route.params.id;
      }

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(
          response.data,
          'Assigned Documents Export.xlsx',
        );
      });
    },
    goToDetails(item) {
      this.$store.commit('updateSelectedAssignedDocument', item);
      if (
        this.$route.name == 'CompanyAssignedDocuments' ||
        this.$route.name == 'UserAssignedDocuments'
      ) {
        this.$router.push({
          name: 'CompanyAssignedDocumentDetails',
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: 'AssignedDocumentDetails',
          params: { id: item.id },
        });
      }
    },
  },
  mounted() {
    if (
      this.$route.name == 'AssignedDocuments' &&
      !phantomAccessCheck(this, 'Assigned Documents')
    ) {
      return;
    }
    this.getRouteQueries();
    if (this.filters.title != '') {
      this.$refs.searchBar.search = this.filters.title;
    }

    const interval = setInterval(() => {
      if (this.$refs.filters) {
        this.$refs.filters.setFilters();
        clearInterval(interval);
      }
    }, 50);
  },
  beforeRouteLeave(to, from, next) {
    // get rid of selection if user nav to another view
    if (to.name != from.name) {
      this.$store.commit('updateAssignedDocumentSelected', []);
    }
    next();
  },
};
</script>

<style>
.signed-documents .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
